import React from "react";
const DiscordIcon = (props: React.SVGAttributes<SVGSVGElement>) => {
  return (
    <svg
      fill="currentColor"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 116.7 83.3"
      {...props}
    >
      <path d="M102.3,10.4C88-.3,74.3,0,74.3,0L72.9,1.6c17,5.1,24.9,12.6,24.9,12.6A83.13,83.13,0,0,0,67.7,4.7a84.61,84.61,0,0,0-20.2.2,9.85,9.85,0,0,0-1.7.2,78.35,78.35,0,0,0-22.7,6.3c-3.7,1.6-5.9,2.8-5.9,2.8s8.2-7.9,26.2-13L42.4,0s-13.6-.3-28,10.4C14.4,10.4,0,36.3,0,68.2c0,0,8.4,14.4,30.5,15.1,0,0,3.7-4.4,6.7-8.2C24.5,71.3,19.7,63.4,19.7,63.4s1,.7,2.8,1.7c.1,0,.2.1.4.2s.6.3.9.5a58.6,58.6,0,0,0,7.3,3.4,76.13,76.13,0,0,0,14.7,4.3,70.05,70.05,0,0,0,25.9.1,71.71,71.71,0,0,0,14.5-4.3,57.22,57.22,0,0,0,11.5-5.9s-5,8.1-18.1,11.8c3,3.7,6.6,8,6.6,8,22.1-.7,30.5-15.1,30.5-15C116.7,36.3,102.3,10.4,102.3,10.4ZM39.7,58.9c-5.6,0-10.2-4.9-10.2-11s4.5-11,10.2-11,10.3,4.9,10.2,11S45.4,58.9,39.7,58.9Zm36.5,0C70.6,58.9,66,54,66,47.9s4.5-11,10.2-11,10.2,4.9,10.2,11S81.9,58.9,76.2,58.9Z" />
    </svg>
  );
};

export default DiscordIcon;
